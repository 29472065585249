import React, { memo } from 'react';
// gatsby
import { useStaticQuery, graphql } from 'gatsby';
//styles
import styles from './client-reviews.module.css';
//components
import ReviewCard from './review-card';

function ClientReviews() {
  const data = useStaticQuery(graphql`
    query {
      theoMendez: file(relativePath: { eq: "theo-mendez.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      kalev: file(relativePath: { eq: "kalev.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      michelleBowser: file(relativePath: { eq: "michelle-bowser.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  const reviewProps = [
    {
      image: data.theoMendez.childImageSharp.fluid,
      person: 'Theo Mendez',
      role: 'Product Manager,',
      company: 'Snafu Records',
      review: '"They were one of the most productive and responsive teams we ever worked with."',
      link: 'https://clutch.co/profile/brocoders?_gl=1*qphzju*_ga*MTg5ODQwMjc4Ny4xNjQ5NjY3MTY4*_ga_D0WFGX8X3V*MTY1MDQ1MzQzOC45MS4xLjE2NTA0NTM0NDQuNTQ.#review-1905278',
    },
    {
      image: data.kalev.childImageSharp.fluid,
      person: 'Kalev Kärpuk',
      role: 'CEO & Founder,',
      company: ' Adact',
      review:
        '"Brocoders is an encouraging partner — they’re always confident that our software solutions will work."',
      link: 'https://clutch.co/profile/brocoders?_gl=1%2Aqphzju%2A_ga%2AMTg5ODQwMjc4Ny4xNjQ5NjY3MTY4%2A_ga_D0WFGX8X3V%2AMTY1MDQ1MzQzOC45MS4xLjE2NTA0NTM0NDQuNTQ.#review-1813161',
    },
    {
      image: data.michelleBowser.childImageSharp.fluid,
      person: 'Michelle Bowser',
      role: 'Senior Director',
      company: 'of Development Experience, iFit',
      review:
        '"They worked very closely together with our team and were involved in our planning processes."',
      link: 'https://clutch.co/profile/brocoders?_gl=1%2Aqphzju%2A_ga%2AMTg5ODQwMjc4Ny4xNjQ5NjY3MTY4%2A_ga_D0WFGX8X3V%2AMTY1MDQ1MzQzOC45MS4xLjE2NTA0NTM0NDQuNTQ.#review-1938841',
    },
  ];
  return (
    <div className={styles.container}>
      <h2 className={styles.caption}>Clients’ reviews</h2>
      <ul className={styles.list}>
        {reviewProps.map((props) => (
          <li key={props.person} className={styles.reviewCard}>
            <ReviewCard {...props} />
          </li>
        ))}
      </ul>
      <a
        href="https://clutch.co/profile/brocoders#focus"
        target="_blank"
        rel="noopener noreferrer"
        className={styles.link}
      >
        View more 5.0 reviews on Clutch
      </a>
    </div>
  );
}
export default memo(ClientReviews);
