import * as React from 'react';
import { Link } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
//styles
import clsx from 'clsx';
import styles from './get-joined.module.css';
// other
import Text from '../../../images/get-joined.inline.svg';
const Routes = require('../../../components/routes').Routes;

export default function GetJoined() {
  const breakpoint = useBreakpoint();
  return (
    <div className={styles.container}>
      {breakpoint.s ? (
        <div className={styles.textBox}>
          <Text />
        </div>
      ) : (
        <div className={styles.textContainer}>
          <span className={styles.text1}>Want to</span>
          <span className={styles.text2}>work</span>
          <span className={styles.text3}>with us</span>
          <span className={styles.text4}>or be</span>
          <span className={styles.text5}>partner</span>
        </div>
      )}
      <div className={styles.linkBox}>
        <Link className={clsx('btn btn_60 btn__white', styles.btn)} to={Routes.CAREER}>
          careers
        </Link>
        <Link className={clsx('btn btn_60 btn__white', styles.btn)} to={Routes.PARTNERSHIP}>
          partnership
        </Link>
      </div>
    </div>
  );
}
