import * as React from 'react';
import clsx from 'clsx';
//styles
import styles from './aboutus.module.css';
//components
import Header from './header';
import Layout from 'components/layout';
import Footer from 'components/layout/footer';
import OurAdvantages from './our-advantages';
import Leadership from './leadership';
import OurValues from './our-values';
import ClientReviews from './client-reviews';
import ClientsLocation from './clients-location';
import OurProjects from 'components/career/our-projects';
import Awards from './awards';
import InstagramFeed from 'components/career/instagram-feed';
import GetJoined from './get-joined';
import Booking from 'components/feedback';

import { meta } from '../../metaData';

function AboutUS() {
  return (
    <Layout title="About us" navBtnTextColor="#D91965" metaData={{ main: meta.aboutUs }}>
      <Header />
      <main className={clsx('main', styles.main)}>
        <OurAdvantages />
        <ClientsLocation />
        <ClientReviews />
        <OurProjects title="Featured Projects:" backgroundColor="#fafafa" />
        <Leadership />
        <OurValues />
        <Awards />
        <InstagramFeed backgroundColor="#fff" />
        <GetJoined />
        <Booking />
      </main>
      <Footer />
    </Layout>
  );
}

export default AboutUS;
