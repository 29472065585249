import React from 'react';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import clsx from 'clsx';
//styles
import styles from './our-advantages.module.css';

const OurAdvantages = () => {
  const data = useStaticQuery(graphql`
    query {
      img: file(relativePath: { eq: "about-us-01.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 780) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <section className={clsx('section', styles.section)}>
      <div className={styles.benefitsContainer}>
        <div className={styles.desctiptionAdvantages}>
          <p className={styles.aboutUs}>
            Brocoders is a technical partner for building web-based and mobile app products. Should
            they be scaled-up or developed from scratch - we will manage with it.
          </p>
          <p className={styles.aboutUs}>
            Besides programming, our service includes user experience, graphic design and project
            management consultancies.
          </p>
        </div>
        <div className={styles.benefits}>
          <dl className={styles.benefits__item}>
            <dt className={styles.point}>87</dt>
            <dd className={styles.description}>top specialists</dd>
          </dl>
          <dl className={styles.benefits__item}>
            <dt className={styles.point}>8</dt>
            <dd className={styles.description}>years of experience</dd>
          </dl>
          <dl className={styles.benefits__item}>
            <dt className={styles.point}>85</dt>
            <dd className={styles.description}>projects done</dd>
          </dl>
          <dl className={styles.benefits__item}>
            <dt className={styles.point}>60%</dt>
            <dd className={styles.description}>senior engineers</dd>
          </dl>
        </div>
      </div>
      <div className={styles.imgContainer}>
        <Img
          fluid={data.img.childImageSharp.fluid}
          className={styles.img}
          alt="photo of founders"
        />
        <div className={styles.signature}>
          <p className={styles.imgSignature}>Founding partners</p>
          <p className={clsx(styles.imgSignature, styles.founders)}>
            Rodion Salnik &amp; Andrey Konoplenko
          </p>
        </div>
      </div>
    </section>
  );
};
export default OurAdvantages;
