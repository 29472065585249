import React from 'react';
import clsx from 'clsx';
import loadable from '@loadable/component';
//styles
import styles from './our-values.module.css';

const Care = loadable(() => import('../../../images/career/care.inline.svg'));
const Honesty = loadable(() => import('../../../images/career/honesty.inline.svg'));
const Excellence = loadable(() => import('../../../images/career/excellence.inline.svg'));
const Authentic = loadable(() => import('../../../images/career/authentic.inline.svg'));

function OurValues() {
  return (
    <section className={clsx('section', styles.section)}>
      <p className={styles.motto}>Our Values</p>
      <div className={styles.images}>
        <Care className={styles.img} />
        <Honesty className={styles.img} />
        <Excellence className={styles.img} />
        <Authentic className={styles.img} />
      </div>
    </section>
  );
}

export default OurValues;
