import * as React from 'react';
//components
import Wave from 'pages/components/header/wave';
import Contacts from 'pages/components/header/contacts';
//styles
import styles from './header.module.css';

function Header() {
  return (
    <header className={styles.container}>
      <div className={styles.wrapper}>
        <div className="inner">
          <div className={styles.content}>
            <h1 className={styles.title}>Not just a Vendor. Your Partner</h1>
            <p className={styles.text}>
              Having gone a long way from making side projects to global market solutions, we
              figured out how to arrange the development smooth and beneficial
            </p>
            <Contacts containerStyles={styles.buttons} />
          </div>
        </div>
        <Wave color="#ffffff" opacity={0.12} />
      </div>
    </header>
  );
}

export default Header;
