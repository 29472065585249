import React, { memo } from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import loadable from '@loadable/component';
import miniMap from '../../../images/map/mini-map.svg';
//styles
import styles from './clients-location.module.css';

const WordMap = loadable(() => import('./world-map'));

function ClientsLocation() {
  const breakpoints = useBreakpoint();

  return (
    <div className={styles.container}>
      {breakpoints.m ? (
        <>
          <p className={styles.text}>
            We managed to work with the clients regardless their location and time zone
          </p>
          <img src={miniMap} className={styles.mobileMap} alt="Map" />
        </>
      ) : (
        <WordMap />
      )}
    </div>
  );
}
export default memo(ClientsLocation);
