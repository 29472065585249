import React from 'react';
// gatsby
import Img, { FluidObject } from 'gatsby-image';
//styles
import styles from './review-card.module.css';
import clsx from 'clsx';
import StarsIcon from '../../../../images/career/stars.inline.svg';

interface IPerson {
  image: FluidObject;
  person: string;
  role: string;
  company: string;
  review: string;
  link?: string;
}

export default function reviewCard({ image, person, role, company, review, link }: IPerson) {
  return (
    <a className={styles.container} href={link} target="_blank" rel="noreferrer noopener">
      <Img fluid={image} className={styles.image} alt="photo" />
      <p className={clsx(styles.person, styles.role)}>{person}</p>
      <p className={styles.role}>{role}</p>
      <p className={styles.role}>{company}</p>
      <p className={styles.review}>{review}</p>
      <div className={styles.rating}>
        <span className={styles.ratingNumber}>5.0</span>
        <StarsIcon style={{ height: '18px', width: 'auto' }} />
      </div>
    </a>
  );
}
