import React from 'react';
// gatsby
import Img, { FluidObject } from 'gatsby-image';
//styles
import styles from './person-card.module.css';

interface IPerson {
  name: string;
  position: string;
  image: FluidObject;
  question: string;
  answer: string;
  linkedInRoute: string;
}

export default function Person({
  name,
  position,
  image,
  question,
  answer,
  linkedInRoute,
}: IPerson) {
  return (
    <article className={styles.card}>
      <p className={styles.name}>{name}</p>
      <p className={styles.position}>{position}</p>
      <a className={styles.linkedIn} href={linkedInRoute} target="_blank" rel="noreferrer noopener">
        LinkedIn
      </a>
      <Img fluid={image} className={styles.image} />
      <p className={styles.question}>{question}</p>
      <p className={styles.answer}>{answer}</p>
    </article>
  );
}
