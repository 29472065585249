import React, { useState, memo } from 'react';
//gatsby
import { useStaticQuery, graphql } from 'gatsby';
//styles
import styles from './leadership.module.css';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
//components
import Person from './person-card';

function Leadership() {
  const data = useStaticQuery(graphql`
    query {
      rSalnik: file(relativePath: { eq: "about-us-rodion-salnik.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 480) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      aKonoplenko: file(relativePath: { eq: "about-us-andrey-konoplenko.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 480) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      milaSandrygailo: file(relativePath: { eq: "about-us-mila-sandrygailo.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 480) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bohdanaOrel: file(relativePath: { eq: "orel_bohdana.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      artemPanasiuk: file(relativePath: { eq: "panasiuk.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 480) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      yuliiaHlamazdina: file(relativePath: { eq: "about-us-yuliia-hlamazdina.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 480) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  const leaderProps = [
    {
      name: 'Andrey Konoplenko',
      position: 'Co-founder',
      linkedInRoute: 'https://www.linkedin.com/in/andreykonoplenko',
      image: data.aKonoplenko.childImageSharp.fluid,
      question: 'What’s so great about Brocoders?',
      answer:
        'I guess that the key is efficient collaboration. If you find a balance between team players with opposite mindsets and skills - you will lead your company to success.',
    },
    {
      name: 'Rodion Salnik',
      position: 'Co-founder',
      linkedInRoute: 'https://www.linkedin.com/in/rodik',
      image: data.rSalnik.childImageSharp.fluid,
      question: 'What is the key driving force that brings Brocoders to success?',
      answer:
        'As it comes from real world practice, a consolidated team could move mountains. I am so proud to say that my colleagues have done this many times :-) Good teamwork and agility is our response to upcoming challenges.',
    },
    {
      name: 'Artem Panasiuk',
      position: 'Chief Delivery Officer',
      linkedInRoute: 'https://www.linkedin.com/in/artem-panasiuk-57a044a4',
      image: data.artemPanasiuk.childImageSharp.fluid,
      question: 'How do you avoid stressful situations?',
      answer:
        "When evaluating the project's scope and estimating its cost, I want to ensure that our delivery process is straightforward to our client. Sharing our experience helps to build trust and avoid misunderstandings in the future.",
    },
    {
      name: 'Bohdana Orel',
      position: 'Chief Financial Officer',
      linkedInRoute: 'https://www.linkedin.com/in/orelbv',
      image: data.bohdanaOrel.childImageSharp.fluid,
      question: 'What makes Brocoder different?',
      answer:
        'We take care of the financial safety of our clients and make sure their expenses will be reasonable. To avoid overspending, we help determine priorities and keep focusing on them while building software products.',
    },
    {
      name: 'Mila Sandrygailo',
      position: 'Chief People Officer',
      linkedInRoute: 'https://www.linkedin.com/in/mila-sandrygailo',
      image: data.milaSandrygailo.childImageSharp.fluid,
      question: 'What’s so great about Brocoders?',
      answer:
        'Brocoders is not just a software development company. We are a community of talents, who leverage their proficiency working with other amazing people. I hope we will become a number 1 choice for software development in Ukraine one day.',
    },
    {
      name: 'Yuliia Hlamazdina',
      position: 'Chief Marketing Officer',
      linkedInRoute: 'https://www.linkedin.com/in/yulyaglamazdina',
      image: data.yuliiaHlamazdina.childImageSharp.fluid,
      question: 'What is so special about marketing in IT?',
      answer:
        'The market of IT services is highly dynamic. It is challenging to compete with dozens of companies providing the same services. So, it motivates us to do research and update value propositions quite often.',
    },
  ];

  const breakpoint = useBreakpoint();
  const [displayedAll, setDisplayedAll] = useState(false);

  return (
    <div className={styles.container}>
      <h2 className={styles.caption}>Get to know us better</h2>
      {breakpoint.s && (
        <>
          <ul>
            {leaderProps.slice(0, 3).map((props) => (
              <Person {...props} key={props.name} />
            ))}
          </ul>
          {displayedAll ? (
            <ul>
              {leaderProps.slice(3).map((props) => (
                <Person {...props} key={props.name} />
              ))}
            </ul>
          ) : (
            <div className={styles.showMoreBox} onClick={() => setDisplayedAll(true)}>
              <p className={styles.readMore}> Read more</p>
            </div>
          )}
        </>
      )}
      {!breakpoint.s && (
        <>
          <ul className={styles.firstCard}>
            {leaderProps.slice(0, 1).map((props) => (
              <li key={props.name} className={styles.firstPersonCard}>
                <Person {...props} key={props.name} />
              </li>
            ))}
          </ul>
          <ul className={styles.list}>
            {leaderProps.slice(1).map((props) => (
              <li key={props.name} className={styles.personCard}>
                <Person {...props} />
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
}

export default memo(Leadership);
