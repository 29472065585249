import React from 'react';
import clsx from 'clsx';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';
//styles
import styles from './awards.module.css';
interface IAwards {
  isWithCaption?: boolean;
  isGrayBackground?: boolean;
  captionStyles?: string;
  containerStyles?: string;
}

export default function Awards({
  isWithCaption = true,
  isGrayBackground = true,
  captionStyles,
  containerStyles,
}: IAwards) {
  const data = useStaticQuery(graphql`
    query {
      youTeam: file(relativePath: { eq: "you-team-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 125, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      outsourcingCompanies: file(relativePath: { eq: "outsourcing-companies.svg" }) {
        publicURL
      }
      clutch: file(relativePath: { eq: "logo-clutch.png" }) {
        childImageSharp {
          fluid(maxWidth: 125, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      upwork: file(relativePath: { eq: "upwork-logo.svg" }) {
        publicURL
      }
      googFirms: file(relativePath: { eq: "good-firms.svg" }) {
        publicURL
      }
    }
  `);
  const containerClassName: string = (() => {
    if (containerStyles) {
      return containerStyles;
    } else if (isGrayBackground) {
      return clsx(styles.container, 'grey-section');
    }
    return styles.container;
  })();
  return (
    <div className={containerClassName}>
      {isWithCaption && <h2 className={captionStyles ? captionStyles : styles.caption}>Awards</h2>}
      <ul className={styles.list}>
        <li className={styles.item} key="clutch">
          <a
            href="https://clutch.co/profile/brocoders"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            <Img
              fluid={data.clutch.childImageSharp.fluid}
              className={clsx(styles.logo)}
              alt="Clutch"
            />
          </a>
        </li>
        <li className={styles.item} key="goodfirms">
          <a
            target="_blank"
            href="https://www.goodfirms.co/company/brocoders"
            rel="noopener noreferrer"
            className={styles.link}
          >
            <img
              src={data.googFirms.publicURL}
              className={clsx(styles.logo, styles.logo2)}
              alt="good firms"
            />
          </a>
        </li>
        <li className={styles.item} key="youteam">
          <a
            href="https://youteam.io"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            <Img
              fluid={data.youTeam.childImageSharp.fluid}
              className={clsx(styles.logo, styles.logo3)}
              alt="You team"
            />
          </a>
        </li>
        <li className={styles.item} key="upwork">
          <a
            href="https://www.upwork.com/o/companies/~012e8207026744bf72/"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            <img
              src={data.upwork.publicURL}
              className={clsx(styles.logo, styles.logo4)}
              alt="Upwork"
            />
          </a>
        </li>
        <li className={styles.item} key="designrush">
          <a
            href="https://www.designrush.com/agency/profile/brocoders"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            <img
              src={data.outsourcingCompanies.publicURL}
              className={clsx(styles.logo, styles.logo5)}
              alt="outsourcing companies"
            />
          </a>
        </li>
      </ul>
    </div>
  );
}
